import { lazy } from 'react'
const HomeView = lazy(() => import('../views/home/index'))
// const NftView = lazy(() => import('../views/nft/index'))

const RouterList = [
  {
    key: '/',
    path: '/',
    title: 'Home',
    isRouter: true,
    isLink: true,
    meta: {
      title: 'Home',
      keywords: 'keywords',
      description: 'description'
    },
    child: [],
    element: <HomeView />
  },
  {
    key: 'Trade',
    path: '',
    title: 'Trade',
    isRouter: false,
    child: [
      {
        key: 'Trade',
        path: 'https://trade.copycatdex.io/',
        title: 'Trade',
        isRouter: false
      },
      // {
      //   key: 'Swap',
      //   path: '',
      //   title: 'Swap',
      //   isRouter: false
      // },
      {
        key: 'Liquidity',
        path: '',
        title: 'Liquidity',
        isRouter: false
      }
    ]
  },
  {
    key: 'copycat',
    path: 'http://legacy.copycat.finance/',
    title: 'CopycatV1',
    isRouter: false,
    isLink: true,
    child: []
  },
  {
    key: 'bitcat',
    path: 'http://bitcat.copycat.finance/',
    title: 'Bitcat(BRC)',
    isRouter: false,
    isLink: true,
    child: []
  }

  // {
  //   key: 'Ecological Products',
  //   path: '',
  //   title: 'Ecological Products',
  //   isRouter: false,
  //   child: [
  //     {
  //       key: 'Ecological Products',
  //       path: '',
  //       title: 'Ecological Products',
  //       isRouter: false
  //     },
  //     {
  //       key: 'Quant Trading',
  //       path: '',
  //       title: 'Quant Trading',
  //       isRouter: false
  //     },
  //     {
  //       key: 'CopyCat Insurance',
  //       path: '',
  //       title: 'CopyCat Insurance',
  //       isRouter: false
  //     }
  //   ]
  // },
  // {
  //   key: 'Launchpad',
  //   path: '',
  //   title: 'Launchpad',
  //   isRouter: false,
  //   child: []
  // },
  // {
  //   key: '/nft',
  //   path: '/nft',
  //   title: 'NFT',
  //   isRouter: true,
  //   isLink: true,
  //   meta: {
  //     title: 'Home',
  //     keywords: 'keywords',
  //     description: 'description'
  //   },
  //   child: [],
  //   element: <NftView />
  // }
]

export default RouterList
