import React from 'react'
import { Box } from '@mui/material'
import { Connection } from 'connection/types'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { LoadingButton } from '@mui/lab'
import { ExternalLink } from 'theme/components'
import Image from 'components/Image'
// const GreenCircle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexFlow: 'row nowrap',
//   justifyContent: 'center',
//   alignItems: 'center',
//   '& div ': {
//     height: 8,
//     width: 8,
//     marginRight: 8,
//     backgroundColor: theme.palette.success.main,
//     borderRadius: '50%'
//   }
// }))

export default function Option({
  link = null,
  header,
  icon,
  connection,
  active = false,
  id
}: {
  link?: string | null
  header: React.ReactNode
  active?: boolean
  icon: string
  connection?: Connection
  isModal?: boolean
  id: string
}) {
  const { activationState, tryActivation } = useActivationState()

  const activate = () =>
    active &&
    connection &&
    tryActivation(connection, () => {}).catch(err => {
      console.error('error message:', err)
    })

  const isSomeOptionPending = activationState.status === ActivationStatus.PENDING
  const isCurrentOptionPending = isSomeOptionPending && activationState.connection.type === connection?.type

  const content = (
    <>
      {header === 'MetaMask' ? (
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          key={id}
          fullWidth
          sx={{
            marginBottom: '10px!important',
            border: '2px solid #000',
            boxShadow: '2px 2px 0px 0px #000000',
            ':disabled': {
              opacity: 0.5,
              backgroundColor: 'var(--ps-white)',
              color: 'var(--ps-black)'
            },
            height: '61px'
          }}
          loading={isCurrentOptionPending}
          onClick={activate}
          disabled={!active}
        >
          <Box
            width={'100%'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Image width={185} height={45} src={icon}></Image>
          </Box>
        </LoadingButton>
      ) : (
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          key={id}
          sx={{
            width: 'calc(50% - 5px)',
            marginBottom: '10px!important',
            border: '2px solid #000',
            boxShadow: '2px 2px 0px 0px #000000',
            ':disabled': {
              opacity: 0.5,
              backgroundColor: 'var(--ps-white)',
              color: 'var(--ps-black)'
            },
            height: '61px'
          }}
          loading={isCurrentOptionPending}
          onClick={activate}
          disabled={!active}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Image src={icon}></Image>
          </Box>
        </LoadingButton>
      )}
    </>
  )
  if (link) {
    return <ExternalLink href={link}>{content}</ExternalLink>
  }
  return content
}
