import { useMemo } from 'react'
import { useTheme, Box } from '@mui/material'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
// import WalletModal from 'components/Modal/WalletModal/index'
import Spinner from 'components/Spinner'
// import Button from 'components/Button/Button'
import useBreakpoint from 'hooks/useBreakpoint'
import useModal from 'hooks/useModal'

import { useActiveWeb3React } from 'hooks'

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner({ IsNftPage }: { IsNftPage: boolean }) {
  const { account, chainId, errorNetwork } = useActiveWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter(tx => !tx.receipt && tx.from === account).map(tx => tx.hash)
  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()
  const theme = useTheme()
  const { hideModal } = useModal()
  const isDownSm = useBreakpoint()

  if (account && chainId) {
    return (
      <Box
        className="btn"
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        <button>
          {hasPendingTransactions ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Spinner color={IsNftPage ? '#fff' : theme.palette.text.primary} size={isDownSm ? '10px' : '16px'} />
              <Box component="span" sx={{ ml: 3 }}>
                {pending?.length} Pending
              </Box>
            </Box>
          ) : (
            <>{ENSName || shortenAddress(account, isDownSm ? 3 : 4)}</>
          )}
        </button>
      </Box>
    )
  } else if (errorNetwork) {
    return (
      <button
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        {'Wrong'}
      </button>
    )
  } else {
    return (
      <button
        // sx={{
        //   backgroundColor: '#A6FAFF'
        // }}
        // fontSize={16}
        // fontWeight={600}
        // width={104}
        // padding={'0 7px'}
        // height={'100%'}
        // lineHeight={'36px'}
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        Connect Wallet
      </button>
    )
  }
}

export default function Web3Status({ IsNftPage }: { IsNftPage: boolean }) {
  return (
    <>
      <Web3StatusInner IsNftPage={IsNftPage} />

      {/* <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} /> */}
    </>
  )
}
