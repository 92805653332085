import { Suspense, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import Polling from '../components/essential/Polling'
import Popups from '../components/essential/Popups'
BigNumber.config({ EXPONENTIAL_AT: [-7, 40] })
import { ModalProvider } from 'context/ModalContext'
// import { Navigate, Route, Routes } from 'react-router-dom'
// import { useTheme } from '@mui/material'
import { styled } from '@mui/material'
// import Header from '../components/Header'
// import Polling from '../components/essential/Polling'
// import Popups from '../components/essential/Popups'
// import { ModalProvider } from 'context/ModalContext'
// import { routes } from 'constants/routes'
// import Home from 'pages/Home'
// import EditProfile from 'pages/User/EditProfile'
// import Group from 'pages/Activity/Group'
// import MyEvent from 'pages/User/MyEvent'
// import MyGroup from 'pages/User/MyGroup'
// import GroupDetail from 'pages/User/MyGroup/GroupDetail'

// // import { useDispatch } from 'react-redux'
// import Activity from 'pages/Activity/index'
// import MyReward from 'pages/User/MyReward'
// import GoogleAnalyticsReporter from 'components/analytics/GoogleAnalyticsReporter'
import { fetchUserLocation } from 'utils/fetch/location'
import store from 'state'
// import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { useUpdateDaoDataCallback } from 'state/buildingGovDao/hooks'
// import { removeCreateDaoData } from 'state/buildingGovDao/actions'

import 'utils/rem'
// import Layout from './Layout'

import Home from '../views/home'
// import NFT from '../views/nft'
import { Route, Routes } from 'react-router-dom'
import FooterBar from 'components/FooterBar'
import NavBar from 'components/NavBar'
import RouterList from '../router/config'
import './App.css'
import './style.css'
// const AppWrapper = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'flex-start',
//   overflowX: 'hidden',
//   background: theme.palette.background.paper,
//   [`& .border-tab-item`]: {
//     position: 'relative',
//     '&.active': {
//       '&:after': {
//         content: `''`,
//         position: 'absolute',
//         bottom: 0,
//         right: 0,
//         left: 0,
//         height: 4,
//         backgroundColor: theme.palette.text.primary,
//         borderRadius: '2px 2px 0px 0px'
//       }
//     }
//   },
//   [theme.breakpoints.down('md')]: {
//     flexDirection: 'column',
//     height: '100vh'
//   }
// }))

// const ContentWrapper = styled('div')({
//   width: '100%',
//   maxHeight: '100vh',
//   overflow: 'auto',
//   alignItems: 'center',
//   '& .toast-container': {
//     width: 446,
//     zIndex: 199999,
//     '& .Toastify__toast': {
//       borderRadius: '10px',
//       border: '1px solid #97B7EF'
//     },
//     '& .Toastify__toast-body': {
//       justifyContent: 'center',
//       color: '#3F5170',
//       fontWeight: 500,
//       fontSize: 14
//     }
//   }
// })

// const BodyWrapper = styled('div')(({}) => ({
//   minHeight: '100vh'
// }))

const ContentWrapper = styled('div')({
  '& .toast-container': {
    width: 446,
    zIndex: 199999,
    '& .Toastify__toast': {
      borderRadius: '10px',
      border: '1px solid #97B7EF'
    },
    '& .Toastify__toast-body': {
      justifyContent: 'center',
      color: '#3F5170',
      fontWeight: 500,
      fontSize: 14
    }
  }
})

export default function App() {
  // const { headerLinkIsShow } = useUpdateDaoDataCallback()
  // const { pathname } = useLocation()
  // const theme = useTheme()
  // const dispatch = useDispatch()
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return
    fetchUserLocation().then(res => {
      store.dispatch({
        type: 'application/setUserLocation',
        payload: res.data || null
      })
    })
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ModalProvider>
        <ContentWrapper>
          <NavBar routes={RouterList} />
          <Popups />
          <Polling />
          <Routes>
            <Route path={'/'} element={<Home />} />
            {/* <Route path={'/nft'} element={<NFT />} /> */}
          </Routes>
          <FooterBar />
        </ContentWrapper>
      </ModalProvider>
      {/* <Layout /> */}
    </Suspense>
  )
}
