import { useState } from 'react'
import classnames from 'classnames'
import { Row, Col, List, Modal } from 'antd'
import '../style.css'

const Roadmap = [
  {
    id: 1,
    time: 'Q1 2024',
    content: [
      'Test and implement copy trading system',
      'Implement and internally test governance token and equity implementation',
      'Test contracts and spot trading system',
      'Test deployment of in-house BTC Rollup solution',
      'Technical implementation of order book trading based on the BTC network'
    ]
  },
  {
    id: 2,
    time: 'Q2 2024',
    content: [
      'Create and meet liquidity automation solutions for copy trading Implement market interface for copy trading',
      'Conduct essential market pre-launch activities',
      'Internally test BTC EVM solution with RGB network logic',
      'Develop order book-based trading for BRC20/BRC721 assets',
      'Implement automatic scanning and listing of BRC assets',
      'Support user-generated assets launch freely or with conditions',
      'Conduct official operational testing of the trading platform',
      'Support user minting and minting of BRC20 assets',
      'Expand insurance services',
      'Allow external projects to utilize CopyCat insurance'
    ]
  },
  {
    id: 3,
    time: 'Q3 2024',
    content: [
      'Implement complete on-chain order trading',
      'Collaborate with BTC L2 to develop more complex ecosystem applications',
      'Explore the application of Lightning Network technology',
      'Further reduce user on-chain transaction fees',
      'Research and develop Real-World Asset (RWA) insurance',
      'Collaborate with traditional insurance industry firms'
    ]
  },
  {
    id: 4,
    time: 'Q4 2024',
    content: [
      'Become a leading BRC asset trading platform in the industry',
      'Establish necessary liquidity support funds',
      'Allow users to generate income through interaction with the liquidity pool',
      'Conduct internal testing of RWA insurance',
      'Collaborate with DeFi platforms like Comp',
      'Launch public beta of RWA insurance',
      'Cover most open DeFi and Token insurance services'
    ]
  },
  {
    id: 5,
    time: 'Q1-Q2 2025',
    content: [
      'Create in-house Bitcoin L2 trading infrastructure',
      'Build a different trading system from the existing Bitcoin L2 based on faster Lightning Network or Layer2 solutions'
    ]
  },
  {
    id: 6,
    time: 'Q3-Q4 2025',
    content: [
      'Improve platform transaction speed and further reduce transaction costs',
      'Implement and deploy node test networks to provide verification capabilities for L2',
      'Explore the creation of a heterogeneous network'
    ]
  },
  {
    id: 7,
    time: 'Q1-Q2 2026',
    content: [
      'Officially launch exclusive Lightning trading network for Bitcat Dex',
      'Upgrade transaction speed and reduce transaction costs',
      'Test data synchronization capability of the heterogeneous network using node network',
      'Test efficiency of Oracle in the heterogeneous network'
    ]
  },
  {
    id: 8,
    time: 'Q1-Q2 2026',
    content: [
      'Implement and deploy test networks for heterogeneous networks, enabling cross-chain capabilities for different chain assets',
      'Achieve trading capability for any asset',
      'Implement Oracle capabilities in the heterogeneous network',
      'Complete comprehensive trading capability of CopyCat for mainstream Web3 assets and mainstream currencies',
      "Create the network's most efficient and low-cost decentralized inscription trading platform."
    ]
  }
]

const BitCatRoadmap = [
  {
    id: 1,
    time: 'Q1 2024',
    content: [
      'Test and implement copy trading system',
      'Implement and internally test governance token and equity implementation',
      'Test contracts and spot trading system',
      'Test deployment of in-house BTC Rollup solution',
      'Technical implementation of order book trading based on the BTC network'
    ]
  },
  {
    id: 2,
    time: 'Q2 2024',
    content: [
      'Create and meet liquidity automation solutions for copy trading',
      'Implement a market interface for copy trading',
      'Internally test BTC EVM solution with RGB network logic',
      'Develop order book-based trading for BRC20/BRC721 assets',
      'Implement automatic scanning and listing of BRC assets',
      'Support user-generated assets launch freely or with conditions',
      'Conduct official operational testing of the trading platform',
      'Support user minting and minting of BRC20 assets'
    ]
  },
  {
    id: 3,
    time: 'Q3 2024',
    content: [
      'Implement complete on-chain order trading',
      'Collaborate with BTC L2 to develop more complex ecosystem applications',
      'Explore the application of Lightning Network technology',
      'Further reduce user on-chain transaction fees'
    ]
  },
  {
    id: 4,
    time: 'Q4 2024',
    content: [
      'Become a leading BRC asset trading platform in the industry',
      'Establish necessary liquidity support funds',
      'Allow users to generate income through interaction with the liquidity pool'
    ]
  },
  {
    id: 5,
    time: 'Q1-Q2 2025',
    content: [
      'Create in-house Bitcoin L2 trading infrastructure',
      'Build a different trading system from the existing Bitcoin L2 based on faster Lightning Network or Layer2 solutions'
    ]
  },
  {
    id: 6,
    time: 'Q3-Q4 2025',
    content: [
      'Improve platform transaction speed and further reduce transaction costs',
      'Implement and deploy node test networks to provide verification capabilities for L2',
      'Explore the creation of a heterogeneous network'
    ]
  },
  {
    id: 7,
    time: 'Q1-Q2 2026',
    content: [
      'Officially launch exclusive Lightning trading network for Bitcat Dex',
      'Upgrade transaction speed and reduce transaction costs'
    ]
  },
  {
    id: 8,
    time: 'Q3-Q4 2026',
    content: [
      'Implement and deploy test networks for heterogeneous networks enabling cross-chain capabilities for different chain assets',
      'Achieve trading capability for any asset',
      'Implement Oracle capabilities in the heterogeneous network',
      'Complete comprehensive trading capability of CopyCat for mainstream Web3 assets and mainstream currencies',
      'Create the most efficient and low-cost decentralized inscription trading platform in the network.'
    ]
  },
  {
    id: 9,
    time: 'Q1-Q2 2026',
    content: [
      'Officially launch exclusive Lightning trading network for Bitcat Dex',
      'Upgrade transaction speed and reduce transaction costs',
      'Test data synchronization capability of the heterogeneous network using node network'
    ]
  }
]

const HomeView = () => {
  const data = [
    {
      id: 1,
      title: 'PVPAMM Mechanism',
      description:
        'Adoption of "PVPAMM" trading mode, enabling users to provide liquidity to each other. Copycat Dex has innovatively introduced the PVPAMM mechanism, creating a new system where users can provide liquidity to each other. This system includes the PNL Pool and the CUD equity token. '
    },
    {
      id: 2,
      title: 'Copycat Risk Management',
      description:
        'Copycat Dex utilizes multiple on-chain security mechanisms to protect the safety of its contracts. Copycat Dex cooperates with white-hat hackers and auditing institutions for comprehensive security testing. Copycat Dex will expand to the Zk-Rollup chain to further build transactions with privacy and compliance features.'
    },
    {
      id: 3,
      title: 'Lowest Transaction Fees',
      description:
        'The lowest transaction fees in the industry are 0.06% - 0.08% , providing users with an economically efficient trading environment. '
    },
    {
      id: 4,
      title: 'Rollup Solution',
      description:
        'The core idea of the Cat non-custodial asset trading protocol is to utilize the Bitcoin blockchain only when necessary. It leverages the decentralized nature of proof-of-work and the network to achieve double-spending protection and censorship resistance. '
    },
    {
      id: 5,
      title: 'Modular Framework',
      description:
        'On the Bitcat Rollup, we will significantly enhance scalability and flexibility. Bitcat is poised to be the first to improve the Brc20-swap solution by introducing a set of derivative trading modules. Initial functionalities will cover leveraged trading of BRC20 assets and contract transactions. Version 2 of the order book will bring additional derivative functionalities such as options, perpetual contracts, and synthetic assets into play.'
    },
    {
      id: 6,
      title: 'Order Book',
      description:
        'Indeed, Bitcat is meticulously designed to uphold the order book trading model and order matching on Layer 2 using ZK technology, settling transactions on Layer 1 through ZK rollup. '
    }
  ]

  const Partner = [
    {
      img: require('../../static/images/logos/Logo-0.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-1.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-2.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-3.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-4.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-5.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-6.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-7.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-8.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-9.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-10.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-11.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-12.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-13.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-14.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-15.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-16.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-17.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-18.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-19.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-20.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-21.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-22.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-23.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-24.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-25.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-26.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-27.png'),
      path: '/'
    },
    {
      img: require('../../static/images/logos/Logo-28.png'),
      path: '/'
    }
  ]
  const info = () => {
    window.open('https://trade.copycatdex.io/', '_blank')
  }
  return (
    <section className="HomeView">
      <div className="module_">
        <div className="home_text">
          <h1 className="title">Don’t be a Copycat, Be a Legend.</h1>
          <p className="des">
            Copycat Dex is an innovative Peer to Pool derivative protocol based on the PVPAMM mechanism. It has built
            the PNL Pool model and the CUD equity token, creating a unique liquidity system for users to experience
            efficient, quality trading. Copycat Finance supports the BRC20 ecosystem and Bitcat DEX. Copycat is backed
            by BinanceLabs.
          </p>
          <div className="btn_group">
            <button>
              BRC20 Market
              <img title="mi_document" alt="mi_document" src={require('../../static/images/iconoir_arrow-tr.png')} />
            </button>
            <button onClick={() => info()} style={{ cursor: 'pointer' }}>
              Launch DEX
              <img title="mi_document" alt="mi_document" src={require('../../static/images/iconoir_arrow-tr2.png')} />
            </button>
            {/* <div>
              <img title="mi_document" alt="mi_document" src={require('../../static/images/mi_document.png')} />
              Documentation
            </div> */}
          </div>
        </div>
        <div className="show">
          <img title="cat" alt="cat" src={require('../../static/images/cat.png')} />
        </div>
      </div>
      <div className="module_right">
        <div className="imgs">
          <img title="Group 1323" alt="Group 1323" src={require('../../static/images/Group 1323.png')} />
        </div>
        <div className="list_box">
          <div className="title_">
            <span>DEX Core Features</span>
          </div>
          <div className="list_content">
            {data.map(item => {
              return (
                <div className="item" key={item.id + 'list'}>
                  <h4 className="item_title">{item.title}</h4>
                  <p className="item_desc">{item.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="module_center">
        <div className="item">
          <div className="imgs">
            <img
              title="GrFrame 48096143.png"
              alt="Frame 48096143.png"
              src={require('../../static/images/Frame 33.png')}
            />
          </div>
          <div className="text">
            <h4 className="title_text">Copycat-PVPAMM Mechanism</h4>
            <p className="des_text">
              {`
              Copycat Dex is an innovative Peer to Pool derivative protocol based on the PVPAMM mechanism. It has built the PNL Pool model and the CUD equity token, creating a unique liquidity system for users to experience efficient, quality trading. Based on the AMM model, Copycat Dex has innovatively introduced the PVPAMM mechanism, creating a new system where users can provide liquidity to each other. This system includes the PNL Pool and the CUD equity token.
             `}
            </p>
          </div>
        </div>
        <div className="item">
          <div className="imgs">
            <img title="Group 1340" alt="Group 1340" src={require('../../static/images/Frame 48096143.png')} />
          </div>
          <div className="text">
            <h4 className="title_text">Bitcat- BRC20 Market On-Chain Derivative Protocol</h4>
            <p className="des_text">
              Bitcat is building on the RGB Layer2 solution, our aim is to create an innovative Bitcoin Rollup solution.
              We aspire to establish a derivative market that caters to BRC20 assets, providing a wide range of
              functional capabilities. Bitcat is a brand new on-chain derivative protocol supported by Binance Labs. It
              is built on the Cat non-custodial asset trading protocol, which is a BRC20 asset derivative trading
              platform based on the RGB-based BTC Rollup technology solution.
            </p>
          </div>
        </div>
      </div>
      <div className="module_Roadmap">
        <Tab />

        <div className="bg"></div>
      </div>
      <div className="module_Partner">
        <h1>Partner</h1>
        <List
          dataSource={Partner}
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 6
          }}
          renderItem={item => (
            <List.Item>
              <div className="item">
                <a href={item.path}>
                  <img className="tw-w-full" src={item.img} alt={item.path} title={item.path} />
                </a>
              </div>
            </List.Item>
          )}
        ></List>
      </div>
      <div className="container"></div>
    </section>
  )
}

function Tab() {
  const [tab, setTab] = useState<'copycat' | 'bitcat'>('copycat')

  return (
    <div className="w-full">
      <div className="tw-w-full tw-flex tw-justify-center tw-items-end tw-my-[113px]">
        <div>
          <div
            onClick={() => {
              setTab('copycat')
            }}
            className={classnames(
              'tw-text-[32px] tw-cursor-pointer',
              tab === 'copycat' ? 'tw-text-white' : 'gradient-text-1'
            )}
          >
            Copycat Roadmap
          </div>

          <div
            className={classnames('tw-w-full tw-h-[3px] tw-mt-[10px]', tab === 'copycat' ? 'gradient-line' : '')}
          ></div>
        </div>

        <div className="tw-ml-[60px]">
          <div
            onClick={() => {
              setTab('bitcat')
            }}
            className={classnames(
              'tw-text-[32px] tw-cursor-pointer',
              tab === 'bitcat' ? 'tw-text-white' : 'gradient-text-1'
            )}
          >
            Bitcat Roadmap
          </div>
          <div
            className={classnames('tw-w-full tw-h-[3px] tw-mt-[10px]', tab === 'bitcat' ? 'gradient-line' : '')}
          ></div>
        </div>
      </div>
      <div className="tw-max-w-[90%] tw-mx-auto">{tab === 'bitcat' ? <BitcatRoadmapComp /> : <CatRoadmapComp />}</div>
    </div>
  )
}

function BitcatRoadmapComp() {
  return (
    <div className="box">
      <Row gutter={[20, 20]}>
        <Col span={16}>
          <div className="left_text tw-w-full tw-overflow-hidden">
            {BitCatRoadmap.map(item => {
              return (
                <div className="item" key={item.id + 'Roadmap'}>
                  <span className="round">
                    <em></em>
                  </span>
                  <div className="t">
                    <h5>{item.time}</h5>
                    <ul>
                      {item.content.map((its, index) => {
                        return <li key={index + 'road'}>{its}</li>
                      })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </Col>
        <Col span={8}>
          <img
            title="CopySum 1"
            className={'tw-w-[100%] tw-max-w-[370px]'}
            alt="CopySum 1"
            src={require('../../static/images/bitcat-full.png')}
          />
        </Col>
      </Row>
    </div>
  )
}

function CatRoadmapComp() {
  return (
    <div className="box">
      <Row gutter={[20, 20]}>
        <Col span={16}>
          <div className="left_text">
            {Roadmap.map(item => {
              return (
                <div className="item" key={item.id + 'Roadmap'}>
                  <span className="round">
                    <em></em>
                  </span>
                  <div className="t">
                    <h5>{item.time}</h5>
                    <ul>
                      {item.content.map((its, index) => {
                        return <li key={index + 'road'}>{its}</li>
                      })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </Col>
        <Col span={8}>
          <div className="tw-flex tw-flex-col tw-gap-y-[100px]">
            <img
              title="CopySum 1"
              className={'tw-w-[100%] tw-max-w-[370px]'}
              alt="CopySum 1"
              src={require('../../static/images/roadmap-cat-1.png')}
            />
            <img
              title="Group 1332.png"
              className={'tw-w-[100%] tw-max-w-[370px]'}
              alt="Group 1332.png"
              src={require('../../static/images/Group1332.png')}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HomeView
