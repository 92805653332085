import { Link, useLocation, useNavigate } from 'react-router-dom'
import './style.css'
import { useState } from 'react'
import Web3Status from 'components/Header/Web3Status'
import WalletModal from 'components/Modal/WalletModal/index'
const NavBar = ({ routes }: { routes: any }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const changePath = (url: string) => {
    navigate(url)
  }
  const [open, setOpen] = useState(false)

  const switchToNew = (path: string) => {
    if (path.indexOf('http') !== -1) {
      // 如果是外部链接，打开新标签页
      window.open(path, '_blank')
    } else {
      // 否则使用内部路由跳转
      changePath(path)
    }
  }
  return (
    <header className="container">
      <nav className="pc">
        <Link to="/">
          <img alt="logo" src={require('../static/images/Logo - Finance.png')} title="logo" />
        </Link>
        <ul>
          {routes.map((route: any) => {
            return (
              <li key={route.key}>
                <Link
                  className={location.pathname === route.path ? 'nav-link active' : 'nav-link'}
                  onClick={() => switchToNew(route.path)}
                  to={'/'}
                >
                  <div className="c">
                    {route.title}
                    <img
                      style={{
                        display: route.child.length > 0 ? 'block' : 'none'
                      }}
                      alt="drop-down"
                      src={require('../static/images/iconamoon_arrow-down-2.png')}
                      title="drop-down"
                    />
                  </div>
                  {route.child.length > 0 && (
                    <div className="child">
                      {route.child.map((child: any) => {
                        return (
                          <div
                            key={child.key}
                            onClick={() => {
                              switchToNew(child.path)
                            }}
                          >
                            {child.title}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </Link>
              </li>
            )
          })}
        </ul>
        <Web3Status IsNftPage={true} />
      </nav>
      <nav className={open ? 'open_mobi' : 'mobi'}>
        <div className="top">
          <Link to="/">
            {/* <img alt="logo" src={require(`../static/images/${!open ? 'logos1' : 'logo1'}.png`)} title="logo" /> */}
          </Link>
          <span
            onClick={() => {
              setOpen(!open)
            }}
          >
            <img alt="Vector" src={require(`../static/images/${!open ? 'Vector' : 'tabler_x'}.png`)} title="Vector" />
          </span>
        </div>
        <div className="list" style={{ display: open ? 'block' : 'none' }}>
          <ul>
            {routes.map((route: any) => {
              return (
                <li key={route.key}>
                  <div
                    className={location.pathname === route.path ? 'nav-link active' : 'nav-link'}
                    onClick={() => {
                      switchToNew(route.path)
                    }}
                  >
                    <div className="c">
                      {route.title}
                      <img
                        style={{
                          display: route.child.length > 0 ? 'block' : 'none'
                        }}
                        alt="drop-down"
                        src={require('../static/images/iconamoon_arrow-down1.png')}
                        title="drop-down"
                      />
                    </div>
                    {route.child.length > 0 && (
                      <div className="child">
                        {route.child.map((child: any) => {
                          return (
                            <div
                              key={child.key}
                              onClick={() => {
                                switchToNew(child.path)
                              }}
                            >
                              {child.title}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
          <div className="tw-w-full tw-flex tw-justify-center">
            <Web3Status IsNftPage={true} />
          </div>
        </div>
      </nav>
      <WalletModal />
    </header>
  )
}

export default NavBar
